import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import Canvas from "./Canvas";
import { createBrowserHistory } from "history";
import "./portfolio.css";

let browserHistory = createBrowserHistory();

export default class Portfolio extends Component {
  state = { pathname: browserHistory.location.pathname };

  handleHeaderClick = page => event => {
    event.stopPropagation();
    this.setState({ pathname: "/" + page });
  }

  renderHeaderLink = page => {
    return (
      <span className="headerLink">
        <NavLink
          to={"/" + page}
          onClick={this.handleHeaderClick(page)}
          className={({ isActive }) => isActive ? "s-active" : undefined}
        >
          {page}
        </NavLink>
      </span>
    )
  }

  render() {
    const hasRoute = this.state.pathname.length > 2;
    const headerClass = hasRoute ? "header withRoute" : "header";

    return (
      <div className="portfolio">
        <Canvas />
        <div className={headerClass}>
          <div className="headerInner invalid">
            <Link
              to="/"
              className="nameLink"
              onClick={this.handleHeaderClick("")}
            >
              <h1>Haley Thurston</h1>
            </Link>
            <div className="headerLinks">
              {this.renderHeaderLink("code")}
              {" | "}
              {this.renderHeaderLink("writing")}
              {" | "}
              {this.renderHeaderLink("cv")}
              {" | "}
              {this.renderHeaderLink("contact")}
              {/*
                {" | "}
                {this.renderHeaderLink("consulting")}
              */}
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}
