import * as React from "react";
import { pages } from "../constants";
import Section from "../Section";
import Page from ".";

export default function Writing() {
  return (
    <Page>
      <div className="writing">
        <Section sections={pages.writing} />
      </div>
    </Page>
  )
}
