import * as React from "react";
import "./style.css";

export default class Page extends React.Component {
  render() {
    return (
      <div className="page">
        <div className="inner invalid">
          {this.props.children}
        </div>
      </div>
    )
  }
}
