import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { createMemoryHistory, createBrowserHistory } from "history";
import './index.css';
import Portfolio from './Portfolio';
import Code from "./Page/Code";
import Writing from "./Page/Writing";
import Cv from "./Page/CV";
import Contact from "./Page/Contact";
// import Consulting from "./Page/Consulting";
import registerServiceWorker from './registerServiceWorker';

let browserHistory = createBrowserHistory();
const history = createMemoryHistory();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter location={history.location} navigator={history}>
    <Portfolio>
      <Routes>
        <Route path="/" element={<span />} />
        <Route path="writing" element={<Writing />} />
        <Route path="code" element={<Code />} />
        <Route path="cv" element={<Cv />} />
        <Route path="contact" element={<Contact />} />
        {/*}<Route path="consulting" element={<Consulting />} />*/}
      </Routes>
    </Portfolio>
  </BrowserRouter>
);
registerServiceWorker();
