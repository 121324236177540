import * as React from "react";
import Page from ".";
import { downloadFile } from "../utils";
import "./style.css";

export default function Cv() {
  return (
    <Page>
      <div className="cv">
        <h3 id="education">Education</h3>
        <div>
          <em>Bachelor of Arts, Art History</em><br />
          Yale University, 2013
        </div>
        <br />
        <div>
          <em>Web Development</em>
          <br />
          App Academy, Spring 2015
        </div>
        <br />
        <br />
        <h3 id="skills">Skills</h3>
        Ruby, Rails, Javascript, jQuery, node.js, flux, redux, React.js, Backbone.js, HTML, CSS (LESS, SASS, SCSS),
        flexbox, electron, SQL, git, postgres full text search
        <br />
        <br />
        <br />
        <h3 id="experience">Experience</h3>
        <div>
          <em>Creative Consultant</em> - (2016-present)
        </div>
        <br />
        <div>
          <em>Senior Software Engineer</em> - Abstract (San Francisco, 2017-2020)
        </div>
        <br />
        <div>
          <em>Front-end Software Engineer</em> - TABLE.co (San Francisco, 2015-2016)
        </div>
        <br />
        <div>
          <em>Writing Resident</em> - Ribbonfarm.com (2015)
        </div>
        <br />
        <div>
          <em>Project Assistant</em> - Yale Center for British Art (New Haven, 2009 - 2013), Beinecke Rare Books and Manuscript Library (New Haven, 2013)
        </div>
        <br />
        <div>
          <em>Curatorial Intern</em> - The Royal Institute of British Architects (London, 2010), La Real Academia de Bellas Artes de San Fernando (Madrid, 2011),
          Sala 1 Centro Internazionale d{"'"}Arte Contemporanea (Rome, 2011), Sharjah Art Foundation (Sharjah, 2012),
          and the Goulandris Museum of Cycladic Art (Athens, 2012)
        </div>
        <br />
        <div>
          <em>Gallery Guide</em> - Institute of Sacred Music (New Haven, 2012-2013), Institute of Contemporary Art (Boston, 2013)
        </div>
        <br />
        {/*<div className="downloadLink external">
          {" [ "}<a href="" onClick={downloadFile}>Download CV as PDF</a>{" ] "}
        </div>*/}
      </div>
    </Page>
  )
}
