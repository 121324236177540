import * as React from "react";
import Page from ".";
import "./style.css";

export default function Contact() {
  return (
    <Page>
      <div className="contact">
        <div>
          I{"'"}m available for freelance writing,{" "}creative consulting, <br />
          and good conversation. Send me a message!
        </div>
        <div className="downloadLink external">
          <div>{" [ "}
            <a href="mailto:haleymthurston@gmail.com" target="_blank" rel="noopener noreferrer">
              haleymthurston@gmail.com
            </a>{" ] "}
          </div>
          <div>{" [ "}
            <a href="https://github.com/haleymt" target="_blank" rel="noopener noreferrer">
              github.com/haleymt
            </a>{" ] "}
          </div>
        </div>
      </div>
    </Page>
  )
}
