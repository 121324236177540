import * as React from "react";
import map from "lodash/map";
import cx from "classnames";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import "./style.css";

export default class Section extends React.Component {
  state = { active: "", hide: false };

  scrollToDescription = () => {
    if (this.description) {
      scrollIntoView(this.description, {
        duration: 200,
        block: "end",
        scrollMode: "if-needed"
      })
    }
  }

  handleThumbClick = key => {
    this.setState(prev => ({
      active: prev.active === key ? "" : key,
      hide: true
    }), () => {
      if (this.state.active) {
        this.setState({ hide: false }, () => setTimeout(this.scrollToDescription, 150))
      }
    });
  }

  renderMobileThumb = (thumb) => {
    return (
      <div
        className="image mobile"
        style={{ backgroundImage: `url(${thumb.image})` }}
      >
        {thumb.new &&
          <div
            style={{ backgroundImage: `url(../img/badge.png)`}}
            className="badge"
          />
        }
      </div>
    )
  }

  renderThumb = (thumb, key) => {
    return (
      <div
        key={key}
        className={cx("image", {
          "active": key === this.state.active && !thumb.featured,
          "transparent": thumb.transparent,
          "featured": thumb.featured
        })}
        style={{ backgroundImage: `url(${thumb.image})` }}
        onClick={thumb.featured ? undefined : () => this.handleThumbClick(key)}
      >
        {thumb.new &&
          <div
            style={{ backgroundImage: `url(../img/badge.png)`}}
            className="badge"
          />
        }
        <h3>{thumb.title}</h3>
      </div>
    )
  }

  renderMobile = (section, idx) => {
    const keys = Object.keys(section);
    const { sections } = this.props;

    return map(section, (thumb, key) => {
      const isFirst = idx === 0 && key === keys[0];
      const isLast = idx === sections.length - 1 && key === keys[keys.length - 1];
      const title = thumb.video ? "[Video] " + thumb.title : thumb.title;

      return (
        <div
          key={key}
          className={cx("section", "mobile", {
            "first": isFirst,
            "last": isLast
          })}
        >
        {this.renderMobileThumb(thumb)}
          <div className="description mobile">
            {thumb.title &&
              <div className="title">
                <a href={thumb.titleHref} target="_blank" className="titleLink">
                  <h3>{thumb.mobileTitle || title}</h3>
                  <div className="arrows">>></div>
                </a>
                {thumb.githubHref &&
                  <div className="topRight">
                  [ <a href={thumb.githubHref} target="_blank">github</a> ]
                  </div>
                }
                {thumb.topRight &&
                  <div
                    className="topRight"
                    dangerouslySetInnerHTML={{__html: thumb.topRight}}
                  />
                }
              </div>
            }
            {thumb.body &&
              <div
                className="body"
                dangerouslySetInnerHTML={{__html: thumb.body}}
              />
            }
          </div>
        </div>
      )
    })
  }

  renderSection = (section, idx) => {
    const active = section[this.state.active];
    const thumb = section.featured ? section.featured : active || {};
    const title = thumb.video ? "[Video] " + thumb.title : thumb.title;
    return (
      <React.Fragment key={idx}>
        {this.renderMobile(section, idx)}
        <div
          key={idx}
          className={cx("section", {
            "featured": thumb.featured, "wrap": thumb.wrap
          })}
        >
          {map(section, this.renderThumb)}
          <div
            ref={ref => (this.description = ref)}
            className={cx("description", {
              "hidden": !active && !thumb.featured,
              "invisible": this.state.hide && !thumb.featured,
              "taller": !!section.darkknight
            })}
          >
            {thumb.title &&
              <div className="title">
                <a href={thumb.titleHref} target="_blank" className="titleLink">
                  <h3>
                    <span dangerouslySetInnerHTML={{__html: title}} />
                    <div className="arrows">>></div>
                  </h3>
                </a>
                {thumb.githubHref &&
                  <div className="topRight">
                  [ <a href={thumb.githubHref} target="_blank">github</a> ]
                  </div>
                }
                {thumb.topRight &&
                  <div
                    className="topRight"
                    dangerouslySetInnerHTML={{__html: thumb.topRight}}
                  />
                }
              </div>
            }
            {thumb.body &&
              <div
                className="body"
                dangerouslySetInnerHTML={{__html: thumb.body}}
              />
            }
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return map(this.props.sections, this.renderSection);
  }
}
