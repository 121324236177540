const firebot = {
  titleHref: "https://github.com/haleymt/Firebot",
  title: "Firebot",
  image: "../img/fireEmoji.png",
  transparent: true,
  featured: true,
  githubHref: "https://github.com/haleymt/Firebot",
  body: `<em>Currently on hiatus</em>. Firebot is a Slack App for keeping track of activity in a team's
        public channels. Intended for busy, discussion-oriented Slack groups
        where it might be hard to keep up with which channels to pay attention to.
        Written in Node.js with a noSQL database. Firebot can be installed as an app,
        or you can use the <a href='https://github.com/haleymt/Firebot_CI' target='_blank'>Firebot CI</a>
        repository to include it in your team as a custom integration.`
}

const canvas = {
  titleHref: "https://github.com/haleymt/drawable",
  title: "Drawable",
  image: "../img/canvas.png",
  githubHref: "https://github.com/haleymt/drawable",
  body: "Turn any background into a drawable canvas. You can test it out on this page by clicking and dragging on on the background. Colors change randomly every time you draw a new line (check out how the favicon changes to match, too). Built with React and the HTML5 Canvas."
}

const abstract = {
  titleHref: "https://www.goabstract.com",
  title: "Abstract",
  image: "../img/abstract.png",
  body: "True version control for design. As an early member of the engineering team, Abstract features countless examples of my front-end work. Experience with React, Redux, Rails, and Electron."
}

const crystalQuest = {
  titleHref: "https://haleymt.github.io/CrystalQuest",
  title: "CrystalQuest",
  image: "../img/CrystalQuest.png",
  githubHref: "https://github.com/haleymt/CrystalQuest",
  body: "Crystal Quest is a remake of the classic game designed for the 1987 Macintosh. Each level, players try to collect all the crystals while dodging asteroids and an assortment of alien foes. The game features fifteen levels of increasing difficulty and a competitive list of high scores. It's made with HTML5's Canvas and Audio API, Javascript, and jQuery. All alien sprites are hand-made. See if you can beat it!"
}

const large = {
  titleHref: "http://medium-large.herokuapp.com",
  title: "Large",
  image: "../img/Large.png",
  githubHref: "https://github.com/haleymt/large",
  body: "Large is a clone of Medium.com built on Rails and Backbone.js. Users can post and edit stories, make publications, tag with key words, search, and more."
}

const marker = {
  titleHref: "https://haleymt.github.io/Marker",
  title: "Marker",
  image: "../img/Marker.png",
  break: true,
  githubHref: "https://github.com/haleymt/Marker",
  body: "Marker converts text written in Markdown syntax to HTML. It handles almost all common Markdown forms, including headers, tables, lists, blockquotes, and more. Written in Javascript."
}

// const table = {
//   titleHref: "https://table.co",
//   title: "Table",
//   image: "../img/Table.png",
//   body: "TABLE.co is an online marketplace for expertise. As an early member of the engineering team, I was responsible for building out much of the front-end components and infrastructure. Work was mostly in React and node."
// }

const snake = {
  titleHref: "https://haleymt.github.io/Snake",
  title: "Snake",
  image: "../img/Snake.png",
  githubHref: "https://github.com/haleymt/Snake",
  body: "A simple rendition of the classic game. Written in both Javascript for the web and Ruby for the terminal. Features multiple speed and size options."
}

const videoPalace = {
  titleHref: "http://videopalace.info",
  title: "Robert and Jesse's Video Palace",
  image: "../img/VideoPalace.png",
  break: true,
  githubHref: "https://github.com/haleymt/VideoPalace",
  body: "A simple video portfolio site made for a comedy duo. Video thumbnails can be expanded to a modal and to a full-page size. In the modal view, connected vignettes are displayed in a carousel below the main video."
}

const heroine = {
  titleHref: "http://www.ribbonfarm.com/2015/01/28/the-heroines-journey/",
  title: "The Heroine's Journey",
  image: "../img/MarilynBurns.jpg",
  body: "<em>\"...The Heroine’s Journey is about learning to suffer, endure, and be subjected to indignity while maintaining grace, composure, and patience. While most heroic stories involve some element of perseverance and strength of will, what makes Heroine’s Journey stories different is that a heroine’s perseverance is tested not to see whether she can persevere to achieve a separate goal, but rather simply to see if she can persevere, period.\"</em>"
}

const compression = {
  titleHref: "http://www.ribbonfarm.com/2015/04/22/a-better-art-vocabulary-part-1/",
  title: `A Better Art Vocabulary,      Part I`,
  image: "../img/Malevich.jpg",
  body: "<em>\"...In his Formal Theory of Creativity & Fun & Intrinsic Motivation, AI scientist Jürgen Schmidhuber suggests the idea of “compression” as the explanation for both why art exists and why it is pleasurable. The gist of Schmidhuber’s concept of compression is that the human brain is itself a kind of hard drive with a limited amount of space. Given that the brain is space-limited, it makes sense that information that uses that space efficiently might reward the brain with pleasure.\"</em>"
}

const morality = {
  titleHref: "http://www.ribbonfarm.com/2015/07/09/a-better-art-vocabulary-part-2/",
  title: "A Better Art Vocabulary, Part II",
  image: "../img/dirtyFeet.png",
  break: true,
  body: "<em>\"...Truth itself is a moral problem. And works we criticize for being immoral that are in fact untrue would be better criticized for being false or not optimally true than on nebulously moral terms.\"</em>"
}

const badTaste = {
  titleHref: "http://www.ribbonfarm.com/2015/09/10/why-nerds-have-bad-taste/",
  title: "Why Nerds Have Bad Taste",
  image: "../img/Enterprise.jpeg",
  body: "<em>\"...When an artist appeals to an audience‘s fetishes, it is kind of like playing art on cheat mode. Appealing to an audience’s fetish will be extremely effective at producing a reaction–but only as long as everyone in the audience shares the fetish.\"</em>"
}

const aweDelusion = {
  titleHref: "http://www.ribbonfarm.com/2015/09/10/why-nerds-have-bad-taste/",
  title: "The Awe Delusion",
  image: "../img/casablanca.png",
  topRight: "[featured on <a href='http://boingboing.net/2015/11/13/art-is-a-technology.html'>boing boing</a>]",
  body: "<em>\"...Some time after 1960 filmmakers learned how to tell more story with less. They learned how to convey more information in less time and without losing depth. If this kind of compression is one example of artistic technology, in other words, we’ve gotten so much better at it that even the workingman filmmakers that produce network television can do it. It’s artistic electricity. Literacy.\"</em>"
}

const ketchup = {
  titleHref: "https://carcinisation.com/2014/09/13/ketchup-and-artistic-effectiveness/",
  title: "Ketchup",
  image: "../img/ketchup.jpg",
  break: true,
  body: "<em>\"...Ketchup is a metaphor for things that have been so perfectly engineered that you can no longer really taste the individual flavors that have gone into them. Every component, of hundreds, is in such correct proportion that you can’t imagine the end result tasting any other way. It’s its own perfect chord of flavor. This is a great quality for processed food to have, because the consistency and memorability make branding automatic. Ketchup is popular, but it’s popular because it’s a feat of engineering.\"</em>"
}

const darkknight = {
  titleHref: "https://www.tcj.com/the-dark-knight-returns-art-makes-sense-if-you-force-it-too/",
  oldtitle: "The Dark Knight Returns: Art Makes <br>Sense If You Force It To",
  title: "The Dark Knight Returns: Art Makes Sense If You Force It To",
  image: "../img/dark_knight.png",
  body: "<em>\"...Above all, The Dark Knight Returns stands out for its density and coherence... It captures the absurdity of fighting violence with violence, and the depressing suspicion that maybe violence is inescapable. It portrays a world where we can barely get a handle on who is good and who is evil, but where a relentless media is eager to frame that ambiguity in the simplest, most self-serving terms. It’s about the fear of growing old and powerless, of being helpless in the face of the world’s problems. It’s about the fact that the fear of powerlessness often causes the world’s problems in the first place. \"</em>"
}

const wonka = {
  titleHref: "https://www.youtube.com/watch?v=rqHbVqSYlhI",
  title: "Willy Wonka and the Inventor Mindset",
  image: "../img/wonka.jpg",
  video: true,
  body: "<em>\"...People who are good at art, who are good at inventing, are often characterized by this kind of disguised dismissal. That quality that says, simultaneously, 'you don’t deserve my seriousness yet', and 'but I am giving you an invitation to play'. People that are cargo-culting the impatience of the competent do not leave these sorts of openings. They have no desire to be actually understood, because if they were understood, it would be clear that their motivations had to do with ego, and not making something good.\"</em>"
}

const buffy = {
  titleHref: "https://www.youtube.com/watch?v=YF7t6xftUYA&list=PLIjKWwcD17aV1KNy1Kzo2Ve9UxYCDtSac",
  title: "The Buffy Commentary Project",
  image: "../img/buffy.jpg",
  video: true,
  body: "<em>\"...We’ll be going scene by scene, much like a commentary track, and looking at what the show is doing artistically from moment to moment. We’ll be digging into the show’s themes, motifs, symbols, and techniques as they occur, and also as they develop over time. Every episode will likely have spoilers for the entire show, since our goal here is to understand Buffy as a total piece of art. We want to understand not just how each individual piece of each episode works on its own, but also how all these pieces add up to what the whole thing is.\"</em>"
}

// { featured: { ...darkknight, featured: true, wrap: true } },

export const pages = {
  code: [
    { featured: { ...firebot } },
    { abstract, large, crystalQuest },
    { marker, snake, canvas }
  ],
  writing: [
    { darkknight, buffy, wonka },
    { heroine, compression, morality },
    { badTaste, aweDelusion, ketchup },
  ]
};
