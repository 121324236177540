import * as React from "react";
import { pages } from "../constants";
import Page from ".";
import Section from "../Section";

export default function Code() {
  return (
    <Page>
      <div className="code">
        <Section sections={pages.code} />
      </div>
    </Page>
  )
}
